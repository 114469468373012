/*
 * uglifyjs views.js -c -m -o views.min.js --source-map "filename='views.min.js.map'"
 *
 */
document.addEventListener("DOMContentLoaded", function() {
    const token_auth = "e7e9a71c24b9faf5cb28768faa63d2cb";
    const views = document.getElementById("views");
    main();
    async function main() {
        let url = "https://expo.tyc.edu.tw/matomo/index.php?module=API&method=API.get&idSite=1&date=today&format=json&force_api_session=1&translateColumnNames=1&columns=nb_visits,nb_visits_new,nb_actions&token_auth=" + token_auth + "&period=";
        try {
            const response = await getData(url + "day" + "&date=today");
            views.innerHTML += `<span class="text-success">今日訪客數：${makenumber(response.nb_visits)}, &nbsp; 瀏覽數：${makenumber(response.nb_actions)}, &nbsp; 新訪客數：${makenumber(response.nb_visits_new)}</span><br>`;
        } catch (err) {
            console.log(err);
        }
        try {
            const response = await getData(url + "week" + "&date=today");
            views.innerHTML += `<span class="text-primary">本周訪客數：${makenumber(response.nb_visits)}, &nbsp; 瀏覽數：${makenumber(response.nb_actions)}, &nbsp; 新訪客數：${makenumber(response.nb_visits_new)}</span><br>`;
        } catch (err) {
            console.log(err);
        }
        try {
            const response = await getData(url + "month" + "&date=today");
            views.innerHTML += `<span class="text-info">本月訪客數：${makenumber(response.nb_visits)}, &nbsp; 瀏覽數：${makenumber(response.nb_actions)}, &nbsp; 新訪客數：${makenumber(response.nb_visits_new)}</span><br>`;
        } catch (err) {
            console.log(err);
        }
        url = "https://expo.tyc.edu.tw/matomo/index.php?module=API&method=API.get&idSite=1&format=json&force_api_session=1&translateColumnNames=1&columns=nb_visits,nb_visits_new,nb_actions&token_auth=" + token_auth + "&period=";
        try {
            const response = await getData(url + "day" + "&date=yesterday");
            views.innerHTML += `<div class="clearfix"></div><span class="text-success">昨日訪客數：${makenumber(response.nb_visits)}, &nbsp; 瀏覽數：${makenumber(response.nb_actions)}, &nbsp; 新訪客數：${makenumber(response.nb_visits_new)}</span><br>`;
        } catch (err) {
            console.log(err);
        }
        try {
            const response = await getData(url + "week" + "&date=lastWeek");
            views.innerHTML += `<span class="text-primary">上周訪客數：${makenumber(response.nb_visits)}, &nbsp; 瀏覽數：${makenumber(response.nb_actions)}, &nbsp; 新訪客數：${makenumber(response.nb_visits_new)}</span><br>`;
        } catch (err) {
            console.log(err);
        }
        try {
            const response = await getData(url + "month" + "&date=lastMonth");
            views.innerHTML += `<span class="text-info">上月訪客數：${makenumber(response.nb_visits)}, &nbsp; 瀏覽數：${makenumber(response.nb_actions)}, &nbsp; 新訪客數：${makenumber(response.nb_visits_new)}</span>`;
        } catch (err) {
            console.log(err);
        }
    }
    function getData(ajaxurl) {
        return $.ajax({
            url: ajaxurl,
            type: "GET",
            dataType: "json"
        });
    }
    function makenumber(num) {
        return ("000000" + num).slice(-5);
    }
});